define("discourse/plugins/discourse-workflow/discourse/components/workflow-name-link", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-workflow/discourse/components/workflow-visualisation-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _workflowVisualisationModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WorkflowButtonsComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    showVisualisationModal() {
      this.modal.show(_workflowVisualisationModal.default, {
        model: {
          topic_id: this.args.topic_id,
          workflow_name: this.args.workflow_name
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showVisualisationModal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="workflow-action-button">
          <DButton class="btn-transparent" @action={{this.showVisualisationModal}}>
            {{@label}}
          </DButton>
        </div>
      
    */
    {
      "id": "ZsIQNImR",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"workflow-action-button\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-transparent\"]],[[\"@action\"],[[30,0,[\"showVisualisationModal\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/components/workflow-name-link.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WorkflowButtonsComponent;
});