define("discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/modal/composer-help", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#d-modal-body id="composer-help-modal"}}
    {{#if loadingTags}}
      {{i18n loading}}
    {{else }}
      <div class="composer-help-content">
          {{composer-help-content model=this}}
      </div>
    {{/if}}
  {{/d-modal-body}}
  */
  {
    "id": "thAAmKVw",
    "block": "[[[6,[39,0],null,[[\"id\"],[\"composer-help-modal\"]],[[\"default\"],[[[[41,[30,0,[\"loadingTags\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"loading\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"composer-help-content\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"model\"],[[30,0]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `loadingTags` property path was used in the `discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/modal/composer-help.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.loadingTags}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `loading` property path was used in the `discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/modal/composer-help.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.loading}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-modal-body\",\"if\",\"i18n\",\"div\",\"composer-help-content\"]]",
    "moduleName": "discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/modal/composer-help.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});